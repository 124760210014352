.header {
  display: flex;
  justify-content: space-between;
}

.logo {
  width: 10rem;
  height: 3rem;
}
.header-menu {
  display: flex;
  gap: 2rem;
  list-style: none;
  color: white;
}
.header-menu>li:hover{
    cursor: pointer;
    color: var(--orange);
}